<template>
  <div class="insurance">
    <div class="header">
      <v-tabs v-model="tab" background-color="transparent" v-if="$vuetify.breakpoint.mdAndUp"
              :class="[{'header-tabs-navigation': navigation.scrolled}]">
        <v-tab v-for="item in tabs" :key="item.value">
          {{item.label}}
        </v-tab>
      </v-tabs>
      <v-select :items="selectTabs" item-text="label" item-value="value"
                :class="[{'header-select-tabs-navigation':navigation.scrolled}]"
                v-model="tab" single-line v-else/>
      <div class="mobile-wrapper">
        <div class="header-background" :style="{backgroundImage: 'url(https://ebikeworldfederation.com/images/member/EBWF_MITGLIED_Headerbild_01.jpg)'}">
          <div class="header-title-container">
            <h1>E-Bike Schutz für Jedermann</h1>
            <h3>RUNDUM-SORGLOS-E-BIKE-VERSICHERUNG genießen</h3>
            <h2>
              ab € 70,00 pro Jahr*
              <v-btn color="white" class="calculate-price-button" @click="dialog.priceCalculator = true">
                Vorteilspreis berechnen
              </v-btn>
            </h2>
            <p>*bei Neuwert des E-Bikes von € 2.000,00; ohne Selbstbeteiligung.</p>
          </div>
        </div>
      </div>
    </div>
    <v-main class="insurance-container">
      <v-container class="pt-0">
        <v-row class="align-center justify-center">
          <v-col cols="12" sm="12" md="11" lg="10" xl="10">
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in tabs" :key="item.value" :value="item.value">
                <v-main style="overflow: hidden;">
                  <v-row class="mb-1" style="margin-top: 30px;">
                    <v-col cols="12" v-if="item.value === 0">
                      <Services/>
                    </v-col>
                    <v-col cols="12" v-if="item.value === 1">
                      <f-a-q/>
                    </v-col>
                    <v-col cols="12" v-if="item.value === 2">
                      <Register :priceRangePackage="selectedPriceRangePackage"/>
                    </v-col>
                  </v-row>
                </v-main>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-dialog v-model="dialog.response" scrollable
              max-width="800px"
              :fullscreen="$vuetify.breakpoint.xsOnly"
              transition="slide-x-reverse-transition"
              content-class="register-dialog-container">
      <v-card>
        <v-card-text>
          <v-row v-if="success">
            <v-col cols="12" class="pb-0 pt-0">
              <img v-lazyimg="'https://www.ebikewm.com/images/EBWM_Register_Success.jpg'">
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <h1>
                Sie haben sich erfolgreich registriert. <br/><br/>
                In Kürze werden Sie eine Bestätigung mit weiteren Informationen erhalten.
              </h1>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <img v-lazyimg="'https://www.ebikewm.com/images/EBWM_Register_Error.jpg'">
            </v-col>
            <v-col cols="12">
              <h1>
                Während der Bezahlung ist ein Fehler aufgetreten. <br/><br/>
                Bitte kontaktieren Sie unser Support Team.
              </h1>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="dialog.response = false">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.priceCalculator" scrollable
              max-width="800px"
              :fullscreen="$vuetify.breakpoint.xsOnly"
              transition="slide-x-reverse-transition"
              content-class="price-calculator-dialog-container">
      <v-card>
        <v-card-title class="font-weight-bold">
          Vorteilspreis unverbindlich berechnen
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="mt-4">
              <v-select class="price-selector" solo
                        :items="prices" return-object
                        v-model="priceRangePackage"
                        item-text="value"
                        label="Wähle den Kaufpreis des E-Bikes (unrabattiert)">
                <template v-slot:append-outer>
                  <v-tooltip top content-class="insurance-info">
                    <template v-slot:activator="{ on }">
                      <v-icon color="info" v-on="on">{{icons.info}}</v-icon>
                    </template>
                    <span>
                      Bitte gib hier den Kaufpreis deines E-Bikes samt dazugehörigem Zubehör ohne Abzug von Rabatten,
                      Vergünstigungen an. Hier ist der ursprüngliche Neuwert des E-Bikes wichtig. Diesen findest Du
                      oft unter der Bezeichnung UVP (=unverbindlicher Verkaufspreis) auf der Rechnung. Gib auch bei
                      einem Gebrauchtkauf den aktuellen marktüblichen Neupreis an. Nur bei korrekter Angabe können
                      wir Dir einen ausreichenden Versicherungsschutz garantieren.
                    </span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" class="text-left">
              <h2 class="text--primary">Dein Vorteilspreis:
                <span class="calculated-price">{{priceRangePackage.label}}.00 €</span>
              </h2>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="dialog.priceCalculator = false">Schließen</v-btn>
          <v-btn color="primary" @click="selectPriceRange()">
            E-Bike Schutz für
            <strong style="display: contents;">{{priceRangePackage.label}}.00 €</strong>
            jährlich beantragen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mdiChevronRight, mdiInformation} from '@mdi/js';
import Services from "../components/insurance/Services";
import FAQ from "../components/insurance/FAQ";
import Register from "../components/insurance/Register";
import InsurancePricesUtils from "../utils/InsurancePricesUtils";

export default {
  name: 'member',
  mixins: [ InsurancePricesUtils ],
  components: {
    Services, FAQ, Register,
  },
  metaInfo() {
    return {
      title: "E-Bike World Federation | Mitgliedschaft",
      meta: [
        { name: 'description', content:  'EBWF MITGLIEDSCHAFT▷inkl. E-Bike Rund-um-Schutz \n'
              + '✓E-Bike Schutz gegen Beschädigung, Verschleiß & Diebstahl. \n'
              + '✚Sorglos mit großem Wert ➨ und kleinem Preis genießen!' },
        { property: 'og:title', content: "E-Bike World Federation | Mitgliedschaft" },
        { property: 'og:site_name', content: 'E-Bike World Federation' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]
    }
  },
  data () {
    return {
      tab: 0,
      dialog: {
        response: false,
        priceCalculator: false,
      },
      icons: {
        right: mdiChevronRight,
        info: mdiInformation,
      },
      success: false,
      priceRangePackage: {label: 54, value: '1.00 € - 250.00 €'},
      selectedPriceRangePackage: null,
      navigation: {
        scrolled: false,
        position: 0,
        scrollingDown: false
      }
    }
  },
  created() {
    if (this.$route.query.success !== undefined) {
      this.success = this.$route.query.success === 'true';
      if (this.$route.query.session_id !== undefined && !this.success) {
        // this.$http.get('insurance/cancel/session?sessionId='.concat(this.$route.query.session_id));
      }
      this.dialog.response = true;
      this.$router.replace({});
      if (this.success) {
        // eslint-disable-next-line no-undef
        dataLayer.push({'event': 'purchase.completed'});
      } else {
        // eslint-disable-next-line no-undef
        dataLayer.push({'event': 'purchase.failed'});
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      if (!this.isIE11()) {
        this.navigation.scrolled = window.scrollY > 50;
        this.navigation.scrollingDown = this.navigation.position < window.scrollY;
        this.navigation.position = window.scrollY;
      } else {
        this.navigation.scrolled = window.pageYOffset > 50;
        this.navigation.scrollingDown = this.navigation.position < window.scrollY;
        this.navigation.position = window.pageYOffset;
      }
    });
    this.globalMenuEvents.$on('goToElement', (selector) => {
      if (selector !== undefined) {
        this.$vuetify.goTo(selector, {
          duration: 0,
          offset: 70,
          easing: 'easeInOutCubic',
        });
      }
    });
    this.globalEvents.$on('goToCalculatePrice',() => {
      this.tab = 2;
    });
  },
  methods: {
    isIE11() {
      return window.navigator.userAgent.indexOf('Trident/7.0') > -1;
    },
    selectPriceRange() {
      this.dialog.priceCalculator = false;
      this.selectedPriceRangePackage = this.priceRangePackage;
      this.tab = 2;
    },
  },
  computed: {
    tabs() {
      return [
        {value: 0, label: 'Inkludierte Leistungen', icon: this.icons.right},
        {value: 1, label: 'Häufig gestellte Fragen', icon: this.icons.right},
        {value: 2, label: 'E-Bike Schutz beantragen ', icon: this.icons.right}
      ];
    },
    selectTabs() {
      return [
        {value: 0, label: 'Inkludierte Leistungen'},
        {value: 1, label: 'Häufig gestellte Fragen'},
        {value: 2, label: 'E-Bike Schutz beantragen'}
      ];
    },
  }
}
</script>

<style lang="scss">
@import "../assets/scss/variables";
@import "../assets/scss/layout";
.insurance {
  .header {
    position: relative;
    height: 600px;

    .v-tabs {
      position: fixed;
      top: 20px;
      left: 50%;
      z-index: 101;
      transform: translateX(-50%);
      display: inline-block;
      width: auto;
      &.header-tabs-navigation {
        top: 12px;
      }
      .v-tabs-slider-wrapper {
        display: none;
      }
      .v-tab {
        text-transform: none;
        height: 40px;
        color: $primary-text !important;
        border-radius: 16px !important;
        &::before {
          border-radius: 16px !important;
        }
      }
      .v-tab--active {
        color: white !important;
        background-color: $primary;
        border-radius: 16px;
      }
    }
    .v-select {
      position: fixed;
      top: 80px;
      left: 50%;
      z-index: 101;
      transform: translateX(-50%);
      display: inline-block;
      width: 165px;
      height: 40px;
      font-size: 12px;
      color: white !important;
      caret-color: white !important;
      border-radius: 16px !important;
      &.header-select-tabs-navigation {
        top: 0;
        padding-top: 11px;
      }
      .v-input__slot {
        background-color: $primary;
        border-radius: 16px;
        padding-left: 8px;
      }
      .v-input__append-inner {
        padding-left: 0 !important;
      }
      .v-select__selection--comma {
        margin: 7px 0 7px 0 !important;
        text-overflow: unset !important;
        max-width: 100%;
        width: 100%;
        text-align: center;
        color: white;
      }
      .v-icon {
        color: white !important;
      }
      .v-input__slot {
        &::before {
          display: none !important;
          border: none !important;
        }
        &::after {
          display: none !important;
          border: none !important;
        }
      }
      .v-text-field__details {
        display: none;
      }
      &.header-tabs-navigation {
        top: 12px;
      }
    }

    .header-background {
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 600px;
      position: fixed;
      z-index: 0;

      .header-title-container {
        text-shadow: 2px 2px 4px #000000;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);

        @include MQ(XS) {
          left: 10px;
          transform: none;
        }
        @include MQ(S) {
          width: 600px;
        }

        h1 {
          font-style: italic;
          font-size: 62px;
          line-height: 62px;
          span {
            color: $primary;
          }

          @include MQ(XS) {
            font-size: 35px;
            line-height: 38px;
            margin-bottom: 10px;
          }
          @include MQ(S) {
            font-size: 48px;
            line-height: 48px;
            margin-bottom: 10px;
          }
          @include MQ(M) {
            font-size: 48px;
            line-height: 48px;
          }
          @include MQ(L) {
            font-size: 52px;
            line-height: 52px;
          }
        }
        h1, h2, h3, p {
          color: white;
          text-align: left;
        }
        h3 {
          max-width: 650px;
          font-weight: 400;
        }
        p {
          font-size: 13px;
        }
        @-moz-document url-prefix() {
          left: 50% !important;
        }
      }
    }
    .calculate-price-button {
      text-transform: none;
      margin-left: 20px;
      font-weight: bold;
      height: 30px;
      border-radius: 10px;
      .v-btn__content {
        color: $primary;
      }
      @include MQ(XS) {
        margin-left: 0;
        height: 36px;
      }
    }
  }
  .insurance-container {
    position: relative;
    background: white;
    .insurance-title {
      font-weight: 600 !important;
      font-style: italic;
      font-size: 2.4rem;
      line-height: 2.4rem;
      letter-spacing: 0;
      color: $primary-text;
      word-break: keep-all !important;
      .insurance-title-primary {
        color: $primary;
      }
    }
    .v-tabs {
      .v-tabs-bar {
        background-color: $light-gray !important;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        .v-slide-group__prev {
          display: none !important;
        }
        .v-slide-group__wrapper {
          .v-tabs-bar__content {
            .v-tab {
              letter-spacing: 0.0392857143em;
              text-transform: none;
              font-size: 22px;
              color: $primary-text;
              min-width: 80px;
              @include MQ(M) {
                font-size: 18px;
              }
              @include MQ(S) {
                font-size: 16px;
              }
              @include MQ(XS) {
                font-size: 14px;
              }
              &.v-tab--active {
                color: $primary;
              }
              .v-icon {
                margin-bottom: -3px;
              }
            }
            .v-tabs-slider-wrapper {
              height: 3px !important;
            }
          }
        }
      }
    }
    .v-tabs-items {
      .v-window-item {
        .v-main {
          h1, h2 {
            font-weight: 400;
            @include MQ(XS) {
              font-size: 22px;
            }
          }
          h2 {
            @include MQ(XS) {
              font-size: 18px;
            }
          }
          p {
            .v-icon {
              margin-top: -3px;
            }
          }
          .highlight {
            color: $primary;
          }
          .short-p {
            max-width: 800px;
            display: inline-block;
          }
          .v-card {
            background-color: $basic-gray !important;
            border-radius: 8px;
            .v-card__text {
              color: $primary-text;
              .t-shirt-image {
                max-width: 750px;
                width: 100%;
              }
              .coupon-container {
                background-image: url(https://ebikeworldfederation.com/images/member/EBWF_MITGLIED_Filterbild_01.png);
                height: 400px;
                background-position: center;
                background-size: cover;
                position: relative;
                @include MQ(XS) {
                  height: 200px;
                }
                .icons-container {
                  position: absolute;
                  top: 50%;
                  left: 20px;
                  width: 100%;
                  transform: translateY(-50%);
                  @include MQ(XS) {
                    left: 10px;
                    transform: translateY(-50%);
                  }
                  .percent {
                    height: 80px;
                    margin-bottom: 5px;
                    margin-right: 20px;
                    @include MQ(XS) {
                      margin-right: 10px;
                      height: 40px;
                    }
                  }
                  .logo {
                    height: 100px;
                    @include MQ(XS) {
                      height: 55px;
                    }
                  }
                }
              }
              .print-abo-container {
                img {
                  height: 400px;
                }
              }
            }
          }
          .v-expansion-panel {
            margin-top: 16px;
            border-radius: 10px !important;
            font-style: italic;
            .v-expansion-panel-content {
              font-style: normal;
              text-align: left;
              a {
                text-decoration: none;
              }
            }
            &::after {
              border: none;
            }
          }
        }
      }
    }
    .v-btn {
      margin-left: 0;
      margin-right: 15px;
      text-transform: none;
      border-radius: 10px;
      margin-bottom: 10px;
      @include MQ(XS) {
        margin-right: 10px;
      }
      &.selected {
        background-color: $primary !important;
        color: white;
      }
      &.error {
        background-color: $basic-red !important;
        color: white;
      }
      .v-btn__content {
        font-size: 18px;
      }
    }
    .v-input {
      margin-bottom: 10px;
      border-radius: 10px;
      .v-label {
        font-size: 20px;
        height: 22px;
        color: $primary-text;
      }
      input {
        font-size: 18px;
        max-height: 40px;
      }
    }

    .v-select {
      &.error--text {
        .v-select__slot {
          .v-label {
            color: #F53A33;
          }
        }
      }
    }

    .misc {
      position: absolute;
      opacity: 0;
    }
  }
  .faq-container {
    margin-bottom: 50px;
    position: relative;
    background-color: $basic-gray;
    border-radius: 10px;
    padding-bottom: 30px;
    padding-top: 20px;
    @include MQ(XS) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @include MQ(S) {
      padding-left: 15px;
      padding-right: 15px;
    }
    &.faq-container-offset {
      margin-top: 50px;
    }
    .v-expansion-panel {
      margin-top: 16px;
      border-radius: 10px !important;
      font-style: italic;
      .v-expansion-panel-content {
        font-style: normal;
        a {
          text-decoration: none;
        }
      }
      &::after {
        border: none;
      }
    }
  }
}
.price-calculator-dialog-container {
  .v-btn {
    text-transform: none;
  }
  .v-label {
    color: black;
    font-weight: bold;
    font-size: 20px;
  }
  h2 {
    .calculated-price {
      color: $primary;
    }
  }
}
</style>
