<template>
  <v-row>
    <v-col cols="12" class="pa-4">
      <v-card class="faq-container">
        <v-row class="align-center justify-center">
          <v-col cols="12" sm="12" md="11" lg="11" xl="10">
            <v-row class="text-left">
              <v-col cols="12" class="pb-0">
                <h1 class="insurance-title">PARTNERPROGRAMM „E-Bike Schutz für Jedermann“</h1>
              </v-col>
              <v-col cols="12">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Wer kann am Partnerprogramm teilnehmen?
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Jede/r mit Wohnsitz in Österreich und Deutschland kann den „E-Bike Schutz für Jedermann“ mit
                      Hilfe eines Partner Codes erwerben. Den Partner Code erhältst Du von ausgewählten EBWF Partnern
                      als auch bei der EBWF. Die EBWF vergibt pro Kalenderjahr eine begrenzte Anzahl an
                      Mitgliedschaften. Anfragen hierfür unter
                      <a href="mailto:info@ebikeworldfederation.com" style="text-decoration: none;">
                        info@ebikeworldfederation.com
                      </a>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Wann startet und endet der E-Bike Schutz für Jedermann?
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Der E-Bike Schutz für Jedermann startet am Tag der Anmeldung und ab diesem Zeitpunkt läuft diese
                      ein (1) Jahr. Der E-Bike Schutz für Jedermann wird auf unbestimmte Dauer abgeschlossen,
                      verlängert sich von Jahr zu Jahr automatisch mit einer Kündigungsfrist von 3 Werktagen zum
                      Ablauf der Periode. <br>
                      Du und wir können den Vertrag unter bestimmten Voraussetzungen vorzeitig kündigen.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Wie ist der genaue Ablauf?
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Bei Bestellung und Bezahlung an die E-Bike World Federation erhältst du
                      innerhalb von 3 Werktagen die Versicherungsinformationen unserer Partnerversicherung an
                      deine E-Mail Adresse zugesandt.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Wer ist mein Ansprechpartner?
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Wir stehen für dich als Ansprechpartner via E-Mail oder Telefon (+43 4842 20607) jederzeit
                      zur Verfügung. Ansprechpartner für Schadensmeldungen ist unser Versicherungspartner hepster.
                      Die Login Daten zu deinem Versicherungs-Kundenbereich erhältst du via E-Mail.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Wie errechnet sich der Preis?
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Der Preis orientiert sich am Kaufpreis (unrabattiert) Deines E-Bikes.
                      Gerne kannst Du <a @click="goToPriceCalculation()" style="text-decoration: none;">hier</a>
                      Deinen Preis berechnen.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Gibt es Rabatte für Kinder, Familienmitglieder oder Vereine?
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Zurzeit sind keine Rabatte ǀ Ermäßigungen möglich.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" class="pa-4">
      <v-card class="faq-container">
        <v-row class="align-center justify-center">
          <v-col cols="12" sm="12" md="11" lg="11" xl="10">
            <v-row class="text-left">
              <v-col cols="12" class="pb-0">
                <h1 class="insurance-title">DIE VERSICHERUNG</h1>
              </v-col>
              <v-col cols="12">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Wie erhalte ich die E-Bike-Versicherung
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Du erhältst den Versicherungsschutz von unserem Partner hepster automatisch nach Deiner
                      Anmeldung. Somit ist Dein E-Bike ganz unkompliziert gegen Beschädigung, Diebstahl und
                      Verschleiß abgesichert. Du musst also nichts weiter tun, um die E-Bike-Versicherung zu erhalten.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Wie kann ich die E-Bike-Versicherung verwalten?
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Nach Beantragung erhältst Du zusammen mit Deinen Versicherungsdokumenten per
                      E-Mail ebenfalls Zugang zu Deinem <strong>hepster Kundenkonto</strong>. Dort hast Du Zugriff auf alle
                      Deine Verträge und Dokumente und kannst diese bei Bedarf ganz einfach noch einmal downloaden.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Ich habe eine Frage zur Versicherung, wer ist mein Ansprechpartner?
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Für Fragen rund um Deine E-Bike-Versicherung oder zu den Versicherungsleistungen kannst Du Dich jederzeit an
                      den Kundensupport von hepster wenden. Nutze hierzu einfach das
                      <a href="https://buchung.hepster.com/kontakt#contact" target="_blank" style="text-decoration: none;">
                        Kontaktformular</a>
                      und schreibe eine Nachricht mit Deinem Anliegen. Das Team von hepster wird Deine Anfrage
                      schnellstmöglich bearbeiten.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Selbstbeteiligung
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Mit der inkludierten E-Bike-Versicherung trägst Du im Falle eines Schadens keine Selbstbeteiligung. Das
                      heißt, dass es keiner Zuzahlung von Dir bedarf, wenn ein Schaden von Dir eingereicht und vom
                      Versicherungspartner ersetzt wird.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        E-Bike Unfall
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Solltest Du mit Deinem E-Bike in einen Unfall verwickelt sein und es dadurch beschädigt oder zerstört werden
                      oder abhanden kommen, leistet der Versicherungspartner Entschädigung in Form der Reparaturkosten oder der
                      Wiederbeschaffung zum Neuwert.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Fall- und Sturzschäden
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Wenn Dein E-Bike umfällt oder durch einen Sturz (auch ohne äußere Einwirkung) beschädigt
                      wird, greift der Versicherungsschutz unseres Partners hepster.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Diebstahl
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      E-Bikes wecken bei Langfingern leider großes Interesse. Nur mal kurz abgestellt und schon
                      kann es gestohlen worden sein. Im Rahmen der Mitgliedschaft ist Dein E-Bike sowie das
                      Zubehör davor geschützt. Bitte sorge dafür, dass Dein E-Bike in verkehrsüblicher Weise
                      durch ein Schloss oder mindestens in gleichwertiger Weise gesichert wurde. Gleichwertig
                      bedeutet zum Beispiel, dass Dein E-Bike an einem Fahrradträger mit abschließbarer
                      Rahmenhalterung befestigt ist oder sich in einem verschlossenen Innen- oder Kofferraum
                      eines Autos befindet. <br/>
                      Zusätzlich zum einfachen Diebstahl ist auch der Einbruchdiebstahl
                      versichert. Ein Einbruchdiebstahl ist dann gegeben, wenn sich jemand widerrechtlich Zutritt
                      zu Deiner Wohnung oder einem sonstigen Raum verschafft und dein E-Bike entwendet.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Vandalismus
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Wenn jemand Dein E-Bike mutwillig oder vorsätzlich beschädigt oder zerstört, ist es
                      abgesichert!
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Höchstentschädigung
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Deine Entschädigung leisten wir bis zur Versicherungssumme. Kommt Dein E-Bike abhanden oder
                      wird es zerstört, zahlen wir den Neuwert. Bei Beschädigung erstatten wir die Reparaturkosten,
                      maximal bis zum Neuwert. <br/><br/>
                      Elektronik- und Feuchtigkeitsschäden sind je nach Alter des Akkus ab Erstkauf wie folgt
                      begrenzt: <br/>
                      Bis zu einem Alter von 3 Jahren: 100 % der Reparaturkosten  <br/>
                      Bis zu einem Alter von 6 Jahren: 50 % der Reparaturkosten  <br/>
                      Ab einem Alter von 6 Jahren: 25 % der Reparaturkosten
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Verschleiß
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Viele Teile am Rad können vom Verschleiß betroffen sein - sichere Dich mit der hepster- E-Bike
                      Versicherung auch gegen Verschleiß am E-Bike ab.<br>
                      Unter Verschleiß fällt bei der hepster E-Bike-Versicherung die Abnutzung der technischen Teile an Deinem Rad,
                      die für eine sichere Fahrtüchtigkeit notwendig sind. Dazu gehören Reifen, Bremsbeläge, Fahrradkette und
                      -schaltung und Felgen. Weiterhin ist bei Deinem E-Bike der Verschleiß des Akkus mitversichert, also wenn
                      innerhalb von 3 Jahren die Ladekapazität des fest verbauten Akkus unter 50% der ursprünglichen Kapazität ist.
                      Weitere Informationen zum Versicherungsschutz für Verschleiß findest Du in der AVB. Wichtig zu wissen ist,
                      dass hier eine Wartezeit von 4 Monaten gilt und Dein E-Bike nicht älter als 3 Jahre sein darf.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        E-Bike Alter und Versicherbarkeit
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Dein E-Bike ist schon etwas länger in Gebrauch und Du möchtest es trotzdem gegen alltägliche
                      Gefahren absichern? Kein Problem! Mit dem gebotenen Versicherungsschutz ist dies möglich.
                      Es spielt das Alter Deines E-Bikes keine Rolle.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Keine Nutzungseinschränkung
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Egal, ob Du das E-Bike gewerblich/beruflich oder privat nutzt, dieses mietest oder geliehen
                      hast – wir bieten Dir umfangreichen Versicherungsschutz über die private Nutzung hinaus.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Reichweite
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Dein E-Bike ist weltweit geschützt!
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Wie kann ich einen Schaden melden?
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Kommt es zu einem Schadensfall oder wurde Dein E-Bike gestohlen, kannst Du einen Schaden direkt über den
                      Link in der Bestätigungsmail Deiner hepster E-Bike-Versicherung melden. Alternativ kannst Du hepster den
                      Schaden über das Schadenmeldeportal unter
                      <a href="https://buchung.hepster.com/schaden" target="_blank" style="text-decoration: none;">
                        https://buchung.hepster.com/schaden
                      </a> oder Dein Kundenkonto melden. Bitte beachte: Ein Diebstahl ist unverzüglich der Polizei zu melden
                      und anzuzeigen. Für die Bearbeitung des Diebstahls wird die Kopie der polizeilichen Anzeige benötigt.
                      <br/> <br/>
                      Weitere Informationen rund um die Schadensbearbeitung, die erforderlichen Dokumente und wie lange die
                      Bearbeitung dauert, findest Du in den
                      <a href="https://buchung.hepster.com/faq#faq_report" target="_blank" style="text-decoration: none">FAQ</a>
                      unseres Versicherungspartners hepster unter dem Punkt "Schaden melden".
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>
                        Wie kann ich die E-Bike-Versicherung kündigen?
                      </strong>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Wenn du den Versicherungsschutz für dein E-Bike nicht länger benötigst, dann kannst du bis 3 Tage vor
                      Ablauf deiner Jahresmitgliedschaft via E-Mail (formlos) an
                      <a href="mailto:info@ebikeworldfederation.com">info@ebikeworldfederation.com</a>. Du erhälst
                      dann ein E-Mail zur Bestätigung deiner Kündigung und dem Kündigungsdatum.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    methods: {
      goToPriceCalculation() {
        this.globalEvents.$emit('goToCalculatePrice');
      }
    }
  }
</script>

