<template>
  <div class="insurance-register-container">

    <h2 class="highlight mb-4">Highlights für Dich</h2>

    <p class="mb-1">
      <v-icon class="highlight">{{icons.check}}</v-icon>
      E-Bike Versicherung gegen Beschädigung, Verschleiß & Diebstahl
    </p>

    <v-card class="ml-1 mr-1 mt-4">
      <v-card-title style="text-align: left" class="insurance-title">
        <v-row class="align-center justify-center">
          <v-col cols="12" sm="12" md="11" lg="11" xl="10">
            <v-row>
              <v-col cols="12">
                E-Bike Schutz sichern <br/>
                für <strong v-if="!hasPartnerCode" style="display: contents;color: #059bd2;">
                {{register.package.label}}.00 €</strong>
                <strong v-else style="display: contents;color: #059bd2;">0 €</strong> jährlich
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider/>
      <v-card-text class="text-left pb-12">
        <v-row class="align-center justify-center">
          <v-col cols="12" sm="12" md="11" lg="11" xl="10">
            <v-row>
              <v-col cols="12" class="text-left">
                <v-form v-model="valid"
                        ref="form" lazy-validation>
                  <v-row>
                    <v-col cols="12" md="12" lg="12">
                      <v-select class="price-selector" solo
                                :items="prices" return-object required
                                v-model="register.package"
                                item-text="value"
                                label="Wähle den Kaufpreis des E-Bikes (unrabattiert)">
                        <template v-slot:append-outer>
                          <v-tooltip top content-class="insurance-info">
                            <template v-slot:activator="{ on }">
                              <v-icon color="info" v-on="on">{{icons.info}}</v-icon>
                            </template>
                            <span>
                              Bitte gib hier den Kaufpreis deines E-Bikes samt dazugehörigem Zubehör ohne Abzug von
                              Rabatten, Vergünstigungen an. Hier ist der ursprüngliche Neuwert des E-Bikes wichtig.
                              Diesen findest Du oft unter der Bezeichnung UVP (=unverbindlicher Verkaufspreis) auf der
                              Rechnung. Gib auch bei einem Gebrauchtkauf den aktuellen marktüblichen Neupreis an.
                              Nur bei korrekter Angabe können wir Dir einen ausreichenden Versicherungsschutz
                              garantieren.
                            </span>
                          </v-tooltip>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="pb-0">
                      <v-text-field label="Partner code" solo
                                    validate-on-blur
                                    v-model="register.partnerCode">
                        <template v-slot:append-outer>
                          <v-tooltip top content-class="insurance-info">
                            <template v-slot:activator="{ on }">
                              <v-icon color="info" v-on="on">{{icons.info}}</v-icon>
                            </template>
                            <span>
                              Falls vorhanden: bitte einen Partner Code eintragen
                            </span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <v-text-field
                          label="Vorname" solo
                          v-model="register.firstName"
                          :rules="rules"
                          validate-on-blur required
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <v-text-field
                          label="Nachname" solo
                          v-model="register.lastName"
                          :rules="rules"
                          validate-on-blur required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" class="pb-0">
                      <v-text-field v-model="register.birthday" label="Geburtstag" type="date" solo
                                    required :rules="rules" validate-on-blur/>
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0">
                      <v-select v-model="register.sex" :items="sexes" label="Geschlecht" solo
                                persistent-hint :rules="rules" required item-text="label" item-value="value"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                      <v-text-field solo
                          label="Straße und Hausnummer"
                          v-model="register.street"
                          :rules="rules"
                          validate-on-blur required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4" class="pt-0 pb-0">
                      <v-text-field solo
                          label="Postleitzahl"
                          v-model="register.zip"
                          :rules="rules"
                          validate-on-blur required
                      />
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0 pb-0">
                      <v-text-field
                          label="Ort" solo
                          v-model="register.city"
                          :rules="rules"
                          validate-on-blur required
                      />
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0 pb-0">
                      <v-select v-model="register.nationality" :items="countries" label="Nationalität" solo
                                persistent-hint :rules="rules" required item-text="label" item-value="value"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <v-text-field solo
                          label="E-Mail Adresse"
                          v-model="register.mail"
                          :rules="rules"
                          validate-on-blur required
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <v-text-field solo
                          label="E-Mail Adresse wiederholen"
                          v-model="register.repeatMail"
                          :rules="rulesRepeat"
                          validate-on-blur required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-text-field label="Rahmennummer" v-model="register.bikeFrameNumber" solo
                                    validate-on-blur required :rules="rules">
                        <template v-slot:append-outer>
                          <v-tooltip top content-class="insurance-info">
                            <template v-slot:activator="{ on }">
                              <v-icon color="info" v-on="on">{{icons.info}}</v-icon>
                            </template>
                            <span>
                      Bitte gib hier die Rahmennummer Deines E-Bikes an, damit im Schadensfall eine schnelle
                      Abwicklung garantiert ist und bei Fragen schneller geholfen werden kann.
                    </span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-text-field label="Angabe zum E-Bike" :rules="rules" required solo
                                    v-model="register.bikeInformation">
                        <template v-slot:append-outer>
                          <v-tooltip top content-class="insurance-info">
                            <template v-slot:activator="{ on }">
                              <v-icon color="info" v-on="on">{{icons.info}}</v-icon>
                            </template>
                            <span>
                              Bitte mach hier genaue Angaben zum Hersteller und Modell Deines versicherten E-Bikes;
                              empfehlenswert ist auch die Angabe der Rahmennummer. Nur bei korrekter Angabe kann im
                              Falle eines Schadens eine Entschädigung erfolgen.
                            </span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <v-checkbox v-model="register.addAccessories" color="primary">
                        <template v-slot:label>
                          <div>
                            Ich möchte mein Zubehör extra angeben
                            <v-tooltip top content-class="insurance-info">
                              <template v-slot:activator="{ on }">
                                <v-icon color="info" v-on="on" @click.stop>{{icons.info}}</v-icon>
                              </template>
                              <span>
                                Bitte beachte, dass eventuelles Zubehör wie z.B. ein Kindersitz nur bis zu einer
                                Deckung von 100 Euro mitversichert ist.
                              </span>
                            </v-tooltip>
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row v-if="register.addAccessories">
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <v-row v-for="(accessory, index) in register.accessories" :key="index">
                        <v-col cols="10" md="10" lg="10" xl="10" class="pt-0 pb-0">
                          <v-text-field solo
                              label="Hersteller, Modell"
                              v-model="accessory.description"
                              :rules="rules"
                              validate-on-blur required
                          />
                        </v-col>
                        <v-col cols="2" md="2" lg="2" xl="2" class="pt-0 pb-0 text-right">
                          <v-icon large color="error" class="mt-2 mr-1"
                                  @click="removeAccessories(index)">{{icons.minus}}</v-icon>
                          <v-icon large color="primary" class="mt-2" @click="addAccessories()">{{icons.add}}</v-icon>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-divider/>
                  <v-row>
                    <v-col cols="12" class="pt-0 pb-0 accept-rules">
                      <v-checkbox color="primary"
                                  v-model="register.insuranceRules" hide-details
                                  :rules="[v => !!v || 'Pflichtfeld']"  required>
                        <template v-slot:label>
                          <div>
                            Mit meiner Buchung bestätige ich, dass mein Hauptwohnsitz in Österreich oder Deutschland liegt
                            und stimme der Verschaffung des Versicherungsschutzes im Rahmen eines Dienstleistungsvertrages
                            durch den Versicherungspartner hepster zu, habe seitens hepster deren
                            <a target="_blank" :href="register.nationality === 'DE'
                        ? 'https://ebikeworldfederation.com/documents/DE/AGB.pdf'
                        : 'https://ebikeworldfederation.com/documents/AT/AGB.pdf'"
                               style="margin-left: 5px;word-break: keep-all;text-decoration: none;" @click.stop>
                              AGB
                            </a>,
                            <a target="_blank" :href="register.nationality === 'DE'
                        ? 'https://ebikeworldfederation.com/documents/DE/IPID.pdf'
                        : 'https://ebikeworldfederation.com/documents/AT/IPID.pdf'"
                               style="margin-left: 5px;word-break: keep-all;text-decoration: none;" @click.stop>
                              IPID
                            </a> und
                            <a target="_blank" :href="register.nationality === 'DE'
                        ? 'https://ebikeworldfederation.com/documents/DE/Widerrufsbelehrung.pdf'
                        : 'https://ebikeworldfederation.com/documents/AT/Widerrufsbelehrung.pdf'"
                               style="margin-left: 5px;word-break: keep-all;text-decoration: none;" @click.stop>
                              Widerrufsbelehrung
                            </a> zur Kenntnis genommen und akzeptiere die
                            <a target="_blank" :href="register.nationality === 'DE'
                        ? 'https://ebikeworldfederation.com/documents/DE/AVB.pdf'
                        : 'https://ebikeworldfederation.com/documents/AT/AVB.pdf'"
                               style="margin-left: 5px;word-break: keep-all;text-decoration: none;" @click.stop>
                              Allgemeinen Versicherungsbedingungen
                            </a> sowie
                            <a target="_blank" :href="register.nationality === 'DE'
                        ? 'https://ebikeworldfederation.com/documents/DE/Datenschutzinformationen.pdf'
                        : 'https://ebikeworldfederation.com/documents/AT/Datenschutzinformationen.pdf'"
                               style="margin-left: 5px;word-break: keep-all;text-decoration: none;" @click.stop>
                              Datenschutzbestimmungen
                            </a>. Auf dieser Grundlage erhalte ich alle Informationen per E-Mail, die zur optimalen
                            Erfüllung des Versicherungsvertrages dienen.
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pb-0 pt-6">
                      <v-btn color="primary" @click="submit(true)" class="insurance-button"
                             :loading="loading || checkCode" :disabled="loading">
                        E-Bike Schutz für
                        <strong style="display: contents;" v-if="!hasPartnerCode">{{register.package.label}}.00 €</strong>
                        <strong style="display: contents;" v-else>0 €</strong>
                        jährlich beantragen
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="loading" max-width="400px" :fullscreen="$vuetify.breakpoint.smAndDown" persistent>
      <v-card>
        <v-card-text>
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-center" cols="12">
              <h3>Daten werden übermittelt...</h3>
            </v-col>
            <v-col cols="12">
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="6"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.response" scrollable
              max-width="800px"
              :fullscreen="$vuetify.breakpoint.xsOnly"
              transition="slide-x-reverse-transition"
              content-class="register-dialog-container">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="pb-0 pt-0">
              <img v-lazyimg="'https://www.ebikewm.com/images/EBWM_Register_Success.jpg'">
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <h1>
                Sie haben sich erfolgreich registriert. <br/><br/>
                In Kürze werden Sie eine Bestätigung mit weiteren Informationen erhalten.
              </h1>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="dialog.response = false">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.priceRange" max-width="600px"
              content-class="price-range-dialog"
              :fullscreen="$vuetify.breakpoint.smAndDown" persistent>
      <v-card>
        <v-card-title class="error--text">
          Achtung!
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-left black--text">
              Bitte geben Sie den korrekten Kaufpreis (unrabattiert) des E-Bikes ein. Der Kaufpreis bestimmt die
              vereinbarte Versicherungssumme im Schadensfall. <br/>
              Im Schadensfall wird nur bis zur vereinbarten Versicherungssumme geleistet.
            </v-col>
            <v-col cols="12">
              <v-select class="price-selector"
                        :items="prices" return-object required
                        v-model="register.package"
                        item-text="value"
                        label="Wähle den Kaufpreis des E-Bikes (unrabattiert)">
                <template v-slot:append-outer>
                  <v-tooltip top content-class="insurance-info">
                    <template v-slot:activator="{ on }">
                      <v-icon color="info" v-on="on">{{icons.info}}</v-icon>
                    </template>
                    <span>
                      Bitte gib hier den Kaufpreis deines E-Bikes samt dazugehörigem Zubehör ohne Abzug von Rabatten,
                      Vergünstigungen an. Hier ist der ursprüngliche Neuwert des E-Bikes wichtig. Diesen findest Du
                      oft unter der Bezeichnung UVP (=unverbindlicher Verkaufspreis) auf der Rechnung. Gib auch bei
                      einem Gebrauchtkauf den aktuellen marktüblichen Neupreis an. Nur bei korrekter Angabe können
                      wir Dir einen ausreichenden Versicherungsschutz garantieren.
                    </span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="dialog.priceRange = false" class="black--text">Abbrechen</v-btn>
          <v-btn color="primary" @click="submit(false)" class="insurance-button"
                 :loading="loading || checkCode" :disabled="loading">
            Mitgliedschaft für
            <strong style="display: contents;" v-if="!hasPartnerCode">{{register.package.label}}.00 €</strong>
            <strong style="display: contents;" v-else>0 €</strong>
            jährlich beantragen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="snackbar.timeout"
      :bottom="snackbar.bottom"
      :color="snackbar.color"
      v-model="snackbar.value">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" icon style="margin: 0 !important;"
               @click="snackbar.value = false">
          <v-icon>{{icons.close}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import {mdiCheck, mdiCalendar, mdiClose, mdiInformation, mdiPlus, mdiMinus} from '@mdi/js';
  import InsurancePricesUtils from "../../utils/InsurancePricesUtils";
  export default {
    mixins: [
      InsurancePricesUtils
    ],
    props: {
      priceRangePackage: null,
    },
    data() {
      return {
        snackbar: {
          value: false,
          text: '',
          color: 'success',
          timeout: 10000,
          bottom: true,
        },
        icons: {
          check: mdiCheck,
          calendar: mdiCalendar,
          close: mdiClose,
          info: mdiInformation,
          add: mdiPlus,
          minus: mdiMinus
        },
        information : {
          price: false,
        },
        valid: true,
        loading: false,
        menu: {
          birthday: false,
        },
        success: false,
        register: {
          package: {label: 54, value: '1.00 € - 250.00 €'},
          partnerCode: null,
          firstName: null,
          lastName: null,
          birthday: null,
          sex: null,
          nationality: null,
          street: null,
          zip: null,
          city: null,
          mail: null,
          repeatMail: null,
          bikeFrameNumber: null,
          bikeInformation: null,
          ebwfRules: false,
          insuranceRules: false,
          misc: null,
          addAccessories: false,
          accessories: [
            { description: null }
          ]
        },
        dialog: {
          response: false,
          priceRange: false,
        },
        stripe: {
          init: undefined,
        },
        checkCode: false,
        hasPartnerCode: false,
        scrollOptions: {
          duration: 250,
          offset: 80,
          easing: 'easeInOutCubic',
        }
      }
    },
    watch: {
      'register.partnerCode'() {
        if (typeof this.register.partnerCode !== 'undefined' && this.register.partnerCode !== null
            && this.register.partnerCode.length > 11) {
          this.checkPartnerCode();
        } else {
          this.hasPartnerCode = false;
        }
      }
    },
    mounted() {
      if (this.priceRangePackage !== null) {
        this.register.package = this.priceRangePackage;
      }
      // eslint-disable-next-line no-undef
      this.stripe.init = Stripe('pk_live_XYBYmtLKllz5NR5BfoNVeSbR00gtSDQ0fa');
      // eslint-disable-next-line no-undef
      // this.stripe.init = Stripe('pk_test_O8UmGuspsJQmozJuSBpqcPzP003KCzCj33');
      const container = document.querySelector('div');
      container.addEventListener('paste', (event) => {
        const paste = (event.clipboardData || window.clipboardData).getData('text');
        if (paste.indexOf('E-Bikedaten') !== -1
            && paste.indexOf('Kundendaten') !== -1
            && paste.indexOf('Lieferzeitpunkt') !== -1) {
          const pasteArray = paste.split('\n');
          let error = false;
          for (let i = 0; i < pasteArray.length; i += 1) {
            const line = pasteArray[i];
            if (line.indexOf('Nationalität') !== -1) {
              const innerLine = line.split(':');
              if (innerLine.length > 1) {
                if (innerLine[1].trim() === 'Österreich') {
                  this.register.nationality = 'AT';
                } else if (innerLine[1].trim() === 'Deutschland') {
                  this.register.nationality = 'DE';
                } else {
                  this.snackbar.text = 'Nationalität wird nicht unterstützt!';
                  this.snackbar.color = 'error';
                  this.snackbar.timeout = 10000;
                  this.snackbar.value = true;
                  error = true;
                }
              }
            }
          }
          if (!error) {
            let series = '';
            let brand = '';
            let year = '';
            for (let i = 0; i < pasteArray.length; i += 1) {
              const line = pasteArray[i];
              if (line.indexOf('Vorname') !== -1) {
                const innerLine = line.split(':');
                if (innerLine.length > 1) {
                  this.register.firstName = innerLine[1].trim();
                }
              } else if (line.indexOf('Nachname') !== -1) {
                const innerLine = line.split(':');
                if (innerLine.length > 1) {
                  this.register.lastName = innerLine[1].trim();
                }
              } else if (line.indexOf('Geburtstag') !== -1) {
                const innerLine = line.split(':');
                if (innerLine.length > 1 && innerLine[1].indexOf('.') !== -1) {
                  this.register.birthday = this.reverseDate(innerLine[1].trim());
                }
              } else if (line.indexOf('Geschlecht') !== -1) {
                const innerLine = line.split(':');
                if (innerLine.length > 1) {
                  // this.register.sex = innerLine[1].trim();
                }
              } else if (line.indexOf('Strasse und Hausnummer') !== -1) {
                const innerLine = line.split(':');
                if (innerLine.length > 1) {
                  this.register.street = innerLine[1].trim();
                }
              } else if (line.indexOf('Postleitzahl') !== -1) {
                const innerLine = line.split(':');
                if (innerLine.length > 1) {
                  this.register.zip = innerLine[1].trim();
                }
              } else if (line.indexOf('Nationalität') !== -1) {
                const innerLine = line.split(':');
                if (innerLine.length > 1) {
                  if (innerLine[1].trim() === 'Österreich') {
                    this.register.nationality = 'AT';
                  } else {
                    this.register.nationality = 'DE';
                  }
                }
              } else if (line.indexOf('Ort') !== -1) {
                const innerLine = line.split(':');
                if (innerLine.length > 1) {
                  this.register.city = innerLine[1].trim();
                }
              } else if (line.indexOf('E-Mail-Addresse') !== -1) {
                const innerLine = line.split(':');
                if (innerLine.length > 1) {
                  this.register.mail = innerLine[1].trim();
                  this.register.repeatMail = innerLine[1].trim();
                }
              } else if (line.indexOf('Serie') !== -1) {
                const innerLine = line.split(':');
                if (innerLine.length > 1) {
                  this.register.bikeInformation = innerLine[1].trim();
                  series = this.register.bikeInformation;
                }
              } else if (line.indexOf('Neupreis') !== -1) {
                const innerLine = line.split(':');
                if (innerLine.length > 1) { // filterPrice
                  let price = innerLine[1].trim().replace('€', '');
                  price = price.replace(',', '.');
                  this.register.package = this.filterPrice(Number(price));
                }
              } else if (line.indexOf('Marke') !== -1) {
                const innerLine = line.split(':');
                if (innerLine.length > 1) {
                  brand = innerLine[1].trim();
                }
              } else if (line.indexOf('Baujahr') !== -1) {
                const innerLine = line.split(':');
                if (innerLine.length > 1) {
                  year = innerLine[1].trim();
                }
              }
            }
            this.register.bikeFrameNumber = brand.concat(' - ').concat(series).concat(' - ').concat(year);
            this.register.ebwfRules = true;
            this.register.insuranceRules = true;
            event.preventDefault();
          }
        }
      });
    },
    methods: {
      reverseDate(date) {
        const [day, month, year] = date.split('.')
        return `${year}-${month}-${day}`;
      },
      submit(priceRange) {
        try {
          if (!this.$refs.form.validate()) {
            this.snackbar.text = 'Nicht alle Pflichtfelder wurden ausgefüllt!';
            this.snackbar.color = 'error';
            this.snackbar.timeout = 10000;
            this.snackbar.value = true;
          } else if (this.register.package.value === '1.00 € - 250.00 €' && priceRange) {
            this.dialog.priceRange = true;
          } else if (!this.checkDateValidity()) {
            this.snackbar.text = 'Geburtsdatum wurde nicht korrekt eingetragen!';
            this.snackbar.color = 'error';
            this.snackbar.timeout = 10000;
            this.snackbar.value = true;
          } else {
            // eslint-disable-next-line no-console
            this.loading = true;
            const register = {
              partnerCode: this.getPartnerCode(this.register.partnerCode),
              firstName: this.register.firstName,
              lastName: this.register.lastName,
              birthday: this.register.birthday,
              sex: this.register.sex,
              nationality: this.register.nationality,
              street: this.register.street,
              zip: this.register.zip,
              city: this.register.city,
              mail: this.register.mail,
              bikeFrameNumber: this.register.bikeFrameNumber,
              bikeInformation: this.register.bikeInformation,
              bikePriceRange: this.register.package.value,
              subscriptionPrice: this.register.package.label,
              accessories: this.register.addAccessories ? this.register.accessories : []
            }
            this.$http.post('insurance', register).then((data) => {
              if (data.body.registration === 'PARTNER CODE NOT FOUND') {
                this.snackbar.text = 'Partner code konnte nicht gefunden werden!';
                this.snackbar.color = 'error';
                this.snackbar.timeout = 10000;
                this.snackbar.value = true;
                this.loading = false;
              } else if (data.body.registration === 'MAIL ADDRESS ALREADY USED') {
                this.snackbar.text = 'Diese E-Mail-Adresse wurde bereits angemeldet!';
                this.snackbar.color = 'error';
                this.snackbar.timeout = 10000;
                this.snackbar.value = true;
                this.loading = false;
              } else if (data.body.registration === 'PARTNER CODE ALREADY USED') {
                this.snackbar.text = 'Dieses Partner Code wurde bereits entwertet!';
                this.snackbar.color = 'error';
                this.snackbar.timeout = 10000;
                this.snackbar.value = true;
                this.loading = false;
              }
              if (data.body.registration === 'REGISTERED - COMPLETED') {
                this.dialog.response = true;
                // eslint-disable-next-line no-undef
                dataLayer.push({'event': 'purchase.completed'});
                this.resetForm();
                this.loading = false;
              } else if (data.body.registration === 'REGISTERED') {
                this.stripe.init.redirectToCheckout({
                  sessionId: data.body.session.id
                }).then((data) => {
                  this.loading = false;
                  if (data.error) {
                    this.snackbar.text = 'Registrierung konnte nicht abgeschlossen werden!';
                    this.snackbar.color = 'error';
                    this.snackbar.timeout = 10000;
                    this.snackbar.value = true;
                  }
                }, () => {
                  this.loading = false;
                  this.snackbar.text = 'Registrierung konnte nicht abgeschlossen werden!';
                  this.snackbar.color = 'error';
                  this.snackbar.timeout = 10000;
                  this.snackbar.value = true;
                });
              }
            }, (data) => {
              this.loading = false;
              // eslint-disable-next-line no-console
              console.error(data);
              this.snackbar.text = 'Registrierung konnte nicht abgeschlossen werden!';
              this.snackbar.color = 'error';
              this.snackbar.timeout = 10000;
              this.snackbar.value = true;
            });
          }
        } catch (err) {
          this.loading = false;
          this.snackbar.text = 'Registrierung konnte nicht abgeschlossen werden!';
          this.snackbar.color = 'error';
          this.snackbar.timeout = 10000;
          this.snackbar.value = true;
          this.$http.post('error', {
            errorMessage: err,
            url: window.location.href
          });
        }
      },
      getPartnerCode(partnerCode) {
        if (typeof partnerCode === 'undefined' || partnerCode === null || partnerCode.length === 0) {
          return 'KeinPartnerCode';
        }
        return partnerCode;
      },
      resetForm() {
        this.register = {
          package: {label: 47, value: '1.00 € - 250.00 €'},
          partnerCode: null,
          firstName: null,
          lastName: null,
          birthday: null,
          sex: null,
          nationality: null,
          street: null,
          zip: null,
          city: null,
          mail: null,
          repeatMail: null,
          bikeFrameNumber: null,
          bikeInformation: null,
          ebwfRules: false,
          insuranceRules: false,
          misc: null,
          addAccessories: false,
          accessories: [
            { description: null }
          ]
        };
        this.$refs.form.resetValidation();
      },
      addAccessories() {
        this.register.accessories.push({ description: null });
      },
      removeAccessories(index) {
        if (this.register.accessories.length <= 1) {
          this.register.addAccessories = false;
          this.register.accessories = [
            { description: null }
          ];
        } else {
          this.register.accessories.splice(index, 1);
        }
      },
      checkDateValidity() {
        let valid = true;
        if (this.register.birthday === null) {
          valid = false;
        } else if (this.register.birthday === '') {
          valid = false;
        } else {
          try {
            const now = new Date;
            const birthday = this.register.birthday.split('-');
            if (Number(birthday[0]) < Number(1900)) {
              valid = false;
            } else if (now.getFullYear() < Number(birthday[0])) {
              valid = false;
            }
          } catch (error) {
            valid = false;
            // eslint-disable-next-line no-console
            console.error(error.stack);
          }
        }
        return valid;
      },
      checkPartnerCode() {
        this.checkCode = true;
        this.$http.get('partner/check?partnerCode='.concat(this.register.partnerCode)).then((data) => {
          this.hasPartnerCode = data.body.partners.length > 0
              && data.body.partners[0].code === this.register.partnerCode;
          this.checkCode = false;
        }, (data) => {
          this.checkCode = false;
          // eslint-disable-next-line no-console
          console.error(data);
        });
      },
    },
    computed: {
      rules() {
        return [val => (val || '').length > 0 || this.$t('required')];
      },
      countries() {
        return [
          {label: 'Deutschland', value: 'DE'},
          {label: 'Österreich', value: 'AT'}
        ];
      },
      sexes() {
        return [
          {label: 'Weiblich', value: 'women'},
          {label: 'Männlich', value: 'men'}
        ];
      },
      rulesRepeat() {
        return [
          (val) => (val || '').length > 0 || this.$t('required'),
          (v) => v === this.register.mail || 'Die E-Mail Adressen müssen identisch sein.',
        ];
      },
    }
  };
</script>

<style lang="scss">
  @import "../../assets/scss/variables";
  @import "../../assets/scss/layout";
  .insurance-register-container {
    .insurance-title {
      font-weight: 600 !important;
      font-style: italic;
      font-size: 2.4rem;
      line-height: 2.4rem;
      letter-spacing: 0;
      color: $primary-text;
      word-break: keep-all !important;
      .insurance-title-primary {
        color: $primary;
      }
    }
    .price-selector {
      margin-bottom: 0 !important;
      .v-input__control {
        .v-text-field__details {
          display: none;
        }
        .v-input__slot {
          margin-bottom: 0 !important;
        }
      }
    }
    .insurance-button {
      height: 100% !important;
      white-space: unset;
      .v-btn__content {
        height: 100% !important;
        display: contents;
      }
    }
    .v-input {
      .v-label {
        font-size: 16px !important;
      }
    }
    .accept-rules {
      .v-input {
        margin: 10px 0 0 !important;
        .v-label {
          height: 100% !important;
        }
      }
    }
    .v-card {
      border-radius: 8px;
    }
  }
  .price-range-dialog {
    .v-input {
      .v-label{
        color: black;
      }
      .v-text-field__details {
        display: none;
      }
    }
    .v-btn {
      text-transform: none;
    }
  }
  .insurance-info {
    text-align: left;
  }
</style>
