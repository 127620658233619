<template>
  <div>
    <h1 class="mb-8">PARTNERPROGRAMM „E-Bike Schutz für Jedermann“</h1>
    <p>
      <span class="short-p">
        Die E-Bike World Federation (EBWF) ist ein gemeinnütziger Verband (ZVR: 1740897906) mit Sitz
        in Sillian/Österreich. Die EBWF unterstützt gemäß dem Motto „Everyone a winner“ die Förderung
        des E-Bike Sports für Jedermann.
      </span>
    </p>

    <p>
      <span class="short-p">
        E-Bike Schutz für Jedermann – Sicherheit für Jedermann
      </span>
    </p>

    <h1 class="highlight mt-6 mb-6">„E-Bike Schutz für Jedermann“ – DEINE VORTEILE / LEISTUNGEN AUF EINEN BLICK</h1>

    <v-card class="ml-1 mr-1">
      <v-card-title>
        <h1 class="insurance-title">RUNDUM-SORGLOS-E-BIKE-VERSICHERUNG</h1>
      </v-card-title>
      <v-divider/>
      <v-card-text class="text-left">
        <p>Die E-Bike World Federation Partnerversicherung von hepster bietet dir ein umfassendes E-Bike Versicherungspaket
          gegen Beschädigung, Verschleiß und Diebstahl</p>
        <h3 class="mb-5">Highlights</h3>
        <p class="mb-1">
          <v-icon class="highlight">{{icons.check}}</v-icon>
          Vollkaskoversicherung zum fairen Preis
        </p>
        <p class="mb-1">
          <v-icon class="highlight">{{icons.check}}</v-icon>
          E-Bikes ohne Altersbeschränkung (auch Carbon) inkl. Zubehör versicherbar
        </p>
        <p class="mb-1">
          <v-icon class="highlight">{{icons.check}}</v-icon>
          Ohne Selbstbeteiligung, mit Neuwertentschädigung
        </p>
        <p class="mb-1">
          <v-icon class="highlight">{{icons.check}}</v-icon>
          Schutz bei Unfall-, Sturz- und Transportschäden
        </p>
        <p class="mb-1">
          <v-icon class="highlight">{{icons.check}}</v-icon>
          Verschleiß inkl. Reifen, Bremsen und Schaltung
        </p>
        <p class="mb-1">
          <v-icon class="highlight">{{icons.check}}</v-icon>
          Schutz bei Elektronik- und Akkuschäden
        </p>
        <p class="mb-1">
          <v-icon class="highlight">{{icons.check}}</v-icon>
          Schutz bei Diebstahl und Raub
        </p>
        <p class="mb-1">
          <v-icon class="highlight">{{icons.check}}</v-icon>
          Vandalismus und Schäden durch Dritte
        </p>
        <p class="mb-1">
          <v-icon class="highlight">{{icons.check}}</v-icon>
          Weltweite Absicherung
        </p>
        <h3 class="mb-5 mt-5">Versicherungsleistungen im Detail:</h3>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <strong>
                <v-icon color="primary" style="margin-top: -3px">{{icons.tools}}</v-icon>
                Leistungen und Gefahren Deiner E-Bike Versicherung
              </strong>
              <template v-slot:actions>
                <v-icon color="primary">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Versichert ist Dein E-Bike/ Lasten E-Bike
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Zubehör des E-Bikes ist bis 100 € mitversichert
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Versichert sind Eigenschäden sowie Schäden, die durch Dritte verursacht werden
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Keine Nutzungseinschränkung (sowohl gewerbliche/berufliche, als auch private Nutzung,
                auch zur Miete oder Leihe)
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Keine Begrenzung des E-Bike Alters
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Keine Selbstbeteiligung
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                24/7-Schutz bei einfachem Diebstahl, Einbruchdiebstahl und Raub
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Beschädigung und Zerstörung durch E-Bikeunfall
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Beschädigung und Zerstörung durch Fall- oder Sturzschäden
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Abhandenkommen des E-Bikes durch einen Unfall oder Sturz
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Beschädigung und Zerstörung durch Vandalismus
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Sturm, Hagel, Überschwemmung, Lawinen, Erdrutsch
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Unsachgemäße Handhabung
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Versichert ist auch eine Teilentwendung, wenn z. B. ein fest mit dem E-Bike
                verbundenes Teil (wie Gepäckträger oder Akku) gestohlen wird
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Weltweiter Versicherungsschutz
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Entschädigungszahlung spätestens 14 Tage nach endgültiger Feststellung
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Höchstentschädigung bis zur vereinbarten Versicherungssumme
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Als Versicherungswert gilt die Wiederbeschaffung zum Neuwert
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Schäden durch strafbare Handlungen melde bitte unverzüglich der nächstzuständigen oder
                nächsterreichbaren Polizeibehörde
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <strong>
                <v-icon color="primary" style="margin-top: -3px">{{icons.cancel}}</v-icon>
                Was schließt die E-Bike Versicherung aus?
              </strong>
              <template v-slot:actions>
                <v-icon color="primary">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Dein E-Bike/Pedelec und/oder Fahrradanhänger muss verkehrsüblich gesichert sein,
                beispielsweise durch ein handelsübliches separates Fahrradschloss (ohne Mindestwert
                oder gleichwertig), ein fest verbautes Rahmenschloss, durch eine abschließbare
                Rahmenhalterung des Fahrradträgers oder der Aufbewahrung in einem verschlossenen
                Kofferraum, Keller oder Garage. Es ist nicht zwingend nötig, dass das Rad an einem
                festen Gegenstand angeschlossen ist
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Wenn Du Schäden vorsätzlich herbeiführst
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Arglistige Täuschung führt zur Leistungsfreiheit
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Schäden aus Ereignissen, welche bereits bei Versicherungsbeginn eingetreten waren
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Schäden bei Veranstaltungen mit Renncharakter oder Wettbewerben
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Höchstgeschwindigkeitsfahrten (auch Downhill-Fahrten)
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Schäden, die nicht die Gebrauchs- und Funktionsfähigkeit beeinträchtigen (z. B.
                Kratzer, Schrammen, Lack- oder sonstige Schönheitsschäden)
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Schäden durch Rost oder Oxidation
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Schäden durch Be-, Verarbeitung oder Reparatur
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Schäden durch Manipulation des Antriebssystems, nicht fachgerechte Ein- und Umbauten
                oder ungewöhnliche Verwendung Deines E-Bikes/Pedelecs
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Schäden, für die ein Dritter z. B. als Lieferant, Hersteller, Händler, Werkunternehmer
                oder aus Reparaturauftrag haftet
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Schäden, die durch Alkoholkonsum oder Drogen entstehen
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Subsidiarität
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Versicherungsschutz nur, soweit und solange dem keine anwendbaren Wirtschafts-,
                Handels- oder Finanzsanktionen bzw. Embargos der Europäischen Union oder der
                Bundesrepublik Deutschland entgegenstehen
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Bitte melde Schäden durch strafbare Handlungen (z. B. Diebstahl oder Vandalismus)
                der Polizei, damit Dein Schaden durch uns übernommen werden kann
              </p>
              <p class="mb-3 mt-3"><strong>Kein Versicherungsschutz für:</strong></p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Elektrofahrräder, für die eine Zulassungs- oder Versicherungspflicht besteht
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Velomobile/ vollverkleidete Fahrräder
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Eigenbauten
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Dirt-Bikes
              </p>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Nachträglich angebaute optische oder elektronische Zubehörteile, außer wenn sie der Diebstahlsicherung dienen
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <strong>
                <v-icon color="primary" style="margin-top: -3px">{{icons.bike}}</v-icon>
                Welche Elektrischen Fahrräder sind versichert?
              </strong>
              <template v-slot:actions>
                <v-icon color="primary">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              Versichert sind alle gängigen E-Bikes/ Lasten E-Bikes aller Marken einschließlich
              E-Bikes mit Carbonrahmen bis zu einer Motorleistung von 500 Watt und
              Geschwindigkeitsunterstützung bis 25 km/h inklusive E-Pedelecs, Lasten- und Cargo-
              e-Bikes, sowie für deren Funktion dienende Teile (vor allem: Akku) und dazugehöriges
              Zubehör. Ausgenommen sind Elektrofahrräder, für die eine Zulassungs- und
              Versicherungspflicht besteht, wie zum Beispiel S-Pedelecs/ Speed-Pedelecs und
              Eigenbauten.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <strong>
                <v-icon color="primary" style="margin-top: -3px">{{icons.pdf}}</v-icon>
                Dokumente, Informationen & Versicherungspolice
              </strong>
              <template v-slot:actions>
                <v-icon color="primary">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Allgemeine Versicherungsbedingungen (AVB)
              </p>
              <div class="ml-5">
                <div>
                  <v-icon class="highlight">{{icons.circle}}</v-icon>
                  <a href="https://ebikeworldfederation.com/documents/DE/AVB.pdf"
                     target="_blank" style="text-decoration: none;">
                    Download
                  </a>
                  für deutsche Staatsbürger
                </div>
                <div>
                  <v-icon class="highlight">{{icons.circle}}</v-icon>
                  <a href="https://ebikeworldfederation.com/documents/AT/AVB.pdf"
                     target="_blank" style="text-decoration: none;">
                    Download
                  </a>
                  für österreichische Staatsbürger
                </div>
              </div>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Informationsblatt zu Versicherungsprodukt
              </p>
              <div class="ml-5">
                <div>
                  <v-icon class="highlight">{{icons.circle}}</v-icon>
                  <a href="https://ebikeworldfederation.com/documents/DE/IPID.pdf"
                     target="_blank" style="text-decoration: none;">
                    Download
                  </a>
                  für deutsche Staatsbürger
                </div>
                <div>
                  <v-icon class="highlight">{{icons.circle}}</v-icon>
                  <a href="https://ebikeworldfederation.com/documents/AT/IPID.pdf"
                     target="_blank" style="text-decoration: none;">
                    Download
                  </a>
                  für österreichische Staatsbürger
                </div>
              </div>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Allgemeine Geschäftsbedingungen (AGB)
              </p>
              <div class="ml-5">
                <div>
                  <v-icon class="highlight">{{icons.circle}}</v-icon>
                  <a href="https://ebikeworldfederation.com/documents/DE/AGB.pdf" target="_blank"
                     style="text-decoration: none;">Download</a>
                  für deutsche Staatsbürger
                </div>
                <div>
                  <v-icon class="highlight">{{icons.circle}}</v-icon>
                  <a href="https://ebikeworldfederation.com/documents/AT/AGB.pdf" target="_blank"
                     style="text-decoration: none;">Download</a>
                  für österreichische Staatsbürger
                </div>
              </div>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Datenschutzhinweise für den Kunden
              </p>
              <div class="ml-5">
                <div>
                  <v-icon class="highlight">{{icons.circle}}</v-icon>
                  <a href="https://ebikeworldfederation.com/documents/DE/Datenschutzinformationen.pdf"
                     target="_blank" style="text-decoration: none;">
                    Download
                  </a>
                  für deutsche Staatsbürger
                </div>
                <div>
                  <v-icon class="highlight">{{icons.circle}}</v-icon>
                  <a href="https://ebikeworldfederation.com/documents/AT/Datenschutzinformationen.pdf"
                     target="_blank" style="text-decoration: none;">
                    Download
                  </a>
                  für österreichische Staatsbürger
                </div>
              </div>
              <p class="mb-1">
                <v-icon class="highlight">{{icons.check}}</v-icon>
                Widerrufsbelehrung
              </p>
              <div class="ml-5">
                <div>
                  <v-icon class="highlight">{{icons.circle}}</v-icon>
                  <a href="https://ebikeworldfederation.com/documents/DE/Widerrufsbelehrung.pdf"
                     target="_blank" style="text-decoration: none;">
                    Download
                  </a>
                  für deutsche Staatsbürger
                </div>
                <div>
                  <v-icon class="highlight">{{icons.circle}}</v-icon>
                  <a href="https://ebikeworldfederation.com/documents/AT/Widerrufsbelehrung.pdf"
                     target="_blank" style="text-decoration: none;">
                    Download
                  </a>
                  für österreichische Staatsbürger
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>

    <v-card class="ml-1 mr-1 mt-4">
      <v-card-title>
        <h1 class="insurance-title">E-Bike Newsletter – immer TOP informiert</h1>
      </v-card-title>
      <v-divider/>
      <v-card-text class="text-left">
        <p class="mb-1">
          Bleib‘ auf dem Laufenden. Verpass‘ keine Aktion, kein Gewinnspiel, kein Fach-/ Insiderwissen.
        </p>
        <p class="mb-1">
          Im Rahmen der EBWF Mitgliedschaft erhältst Du innerhalb der abgeschlossenen Laufzeit
          automatisiert unseren Newsletter „Everyone a winner“ mit Informationen rund ums E-Bike,
          einmalige Aktionen sowie die Chance zur Teilnahme an Gewinnspielen. Versand E-Bike
          Newsletter (max. 4 x pro Kalenderjahr).
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {mdiChevronRight, mdiTools, mdiFrequentlyAskedQuestions, mdiAccountCardDetailsOutline, mdiCheck,
    mdiCancel, mdiBicycle,mdiFilePdfOutline, mdiCalendar, mdiClose, mdiCircleMedium} from '@mdi/js';
  export default {
    data () {
      return {
        icons: {
          right: mdiChevronRight,
          tools: mdiTools,
          faq: mdiFrequentlyAskedQuestions,
          card: mdiAccountCardDetailsOutline,
          check: mdiCheck,
          cancel: mdiCancel,
          bike: mdiBicycle,
          pdf: mdiFilePdfOutline,
          calendar: mdiCalendar,
          close: mdiClose,
          circle: mdiCircleMedium,
        },
      }
    },
  };
</script>
